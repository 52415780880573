






























import { Component, Vue } from 'vue-property-decorator';
import {PublicBoardTemplates} from "@/graphql/boardTemplate";
import Template from "@/components/workspace/Template.vue";

@Component({
  components: {},
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
    },
  },
})
export default class PublicTemplatesView extends Vue {
  openTemplateModal(template: any) {
    this.$buefy.modal.open({
      component: Template,
      props: {
        template: template,
      },
      parent: this,
      width: '620px',
    });
  }
}
